import type { GetStaticProps, InferGetStaticPropsType } from 'next'
import { useRouter } from 'next/router'
import { BuilderComponent, Builder, builder } from '@builder.io/react'
import Head from 'next/head'
import NotFoundPage from './404'
import { LoadingIndicator } from '../components/LoadingIndicator'
// Existing Builder Experiments for SEM Page
import '../components/SEMPage/sem-old/index'
// Builder Code Component Imports
import '../components/Builder/CodeComponentsRegistration'
import { getBuilderStaticPaths, getBuilderStaticProps } from '../util/builder-functions'
import * as React from 'react'
import { LogOnMount, Segment } from '../lib/segment'

builder.init(process.env.BUILDER_API_KEY)

const pagesWithSegment = ['/sem/', '/cities/', '/get-quote/', '/']

export default function Page({
  page,
  carContent,
  cities,
  reviewCount,
  rating,
}: InferGetStaticPropsType<typeof getStaticProps>) {
  const storeVariation = (_, content) => {
    const variation = content.testVariationName
    if (variation) {
      sessionStorage.setItem('experimentVariation', variation)
    }
  }

  const router = useRouter()
  if (router.isFallback) {
    return <LoadingIndicator />
  }
  const isLive = !Builder.isEditing && !Builder.isPreviewing
  if (!page && isLive) {
    return (
      <>
        <Head>
          <meta name="robots" content="noindex" />
          <meta name="title" />
          <title>404: Not Found - NuBrakes Mobile Repair</title>
        </Head>
        <NotFoundPage />
      </>
    )
  }
  return (
    <>
      <Head>
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <title>NuBrakes Mobile Repair - We Come To You</title>
      </Head>
      {pagesWithSegment.includes(router.asPath) ? (
        <Segment>
          <LogOnMount eventType="Page Viewed" />
          <BuilderComponent
            model="page"
            content={page}
            data={{ carContent, cities, reviewCount, rating }}
            contentLoaded={storeVariation}
          />
        </Segment>
      ) : (
        <BuilderComponent
          model="page"
          content={page}
          data={{ carContent, cities, reviewCount, rating }}
          contentLoaded={storeVariation}
        />
      )}
    </>
  )
}

export const getStaticProps: GetStaticProps = async (context) => {
  return await getBuilderStaticProps('page', context)
}

export const getStaticPaths = async () => {
  // paths only return here when published
  return await getBuilderStaticPaths('page')
}
